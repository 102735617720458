import { useTranslation } from 'react-i18next'
import LangSelector from './components/LangSelector'

import AppConfig from '../resources/app-config'

const CommonFooter = ({ containerClass = '', callCenterClass = 'link-primary', isAuth = true }) => {
    const { t } = useTranslation()

    const colClass = isAuth ? 'col-lg-4 mb-3' : 'col-lg-3 mb-3'

    return (
        <div className={`container-fluid ${containerClass} py-3`}>
            <div className='row text-center'>
                <div className={colClass}>
                    <div>
                        <a href='https://returosgr.ro/gdpr' target='blank' rel='noreferrer' className='footer-link'>
                            {t('GDPR')}
                        </a>
                    </div>
                    <div>
                        <a
                            href='https://returosgr.ro/politica-cookies'
                            target='blank'
                            rel='noreferrer'
                            className='footer-link'
                        >
                            {t('cookies')}
                        </a>
                    </div>
                    <div>
                        <a
                            href='https://returosgr.ro/termeni-si-conditii'
                            target='blank'
                            rel='noreferrer'
                            className='footer-link'
                        >
                            {t('terms')}
                        </a>
                    </div>
                </div>
                <div className={colClass}>
                    <div>
                        <a
                            href='https://returosgr.ro/inregistrare-producatori'
                            target='blank'
                            rel='noreferrer'
                            className='footer-link'
                        >
                            {t('producer_policy')}
                        </a>
                    </div>
                    <div>
                        <a
                            href='https://returosgr.ro/inregistrare-comercianti'
                            target='blank'
                            rel='noreferrer'
                            className='footer-link'
                        >
                            {t('retailer_policy')}
                        </a>
                    </div>
                    <div>
                        <a href='https://returosgr.ro' target='blank' rel='noreferrer' className='footer-link'>
                            {AppConfig.copyright}
                        </a>
                    </div>
                </div>
                <div className={colClass}>
                    <div className='footer-text'>
                        {t('call_center')}
                        {': '}
                        <a
                            href={`tel://${t('call_center_number').replace(/[^+\d]/g, '')}`}
                            target='blank'
                            rel='noreferrer'
                            className={`text-nowrap footer-link ${callCenterClass}`}
                        >
                            {t('call_center_number')}
                        </a>
                        <br />
                        {t('call_center_time')}: {t('call_center_hours')}
                    </div>
                    <div>
                        <a href='mailto:suport@returosgr.ro' target='blank' rel='noreferrer' className='footer-link'>
                            suport@returosgr.ro
                        </a>
                    </div>
                </div>
                {!isAuth && (
                    <div className={colClass}>
                        <LangSelector />
                    </div>
                )}
            </div>
        </div>
    )
}

export default CommonFooter
